/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import ProjectSupervisors from '../components/student/projectSupervisors'
import {useSelector} from 'react-redux'
import {selectAuth} from '../../../redux/selectors/auth'
import get from '../../../lib/get'

import Notifications from '../components/notifications'

import React from 'react'
import StudentProjectsStats from '../components/student/student-projects-stats'

const ProjectsAnalytics: React.FC<{projectsAnalytics: any}> = ({projectsAnalytics}) => (
  <>
    <StudentProjectsStats projectsAnalytics={projectsAnalytics} />
    <ProjectSupervisors projectsSupervisors={projectsAnalytics?.projectsSupervisors} />
  </>
)

const Dashboard: FC<{projectsAnalytics: any; proposalAnalytics: any}> = ({
  projectsAnalytics,
  proposalAnalytics,
}) => {
  return (
    <div className='row g-5' style={{height: '525px'}}>
      <div className='col-lg-6 d-flex flex-column h-100'>
        <ProjectsAnalytics projectsAnalytics={projectsAnalytics} />
      </div>
      <div className='col-lg-6 d-flex flex-column h-100'>
        <Notifications />
      </div>
    </div>
  )
}

const StudentDashboard: FC = () => {
  const {token} = useSelector(selectAuth)
  const [projectsAnalytics, setProjectsAnalytics] = useState<any>(null)
  const [proposalAnalytics, setProposalAnalytics] = useState<any>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const getDashboardData = async () => {
      setIsLoading(true)
      setError(null)
      try {
        if (token) {
          const projectsResponse = await get('projects/student/dashboardData', token)
          const proposalsResponse = await get('proposals/student/dashboardData', token)

          if (projectsResponse?.data) {
            setProjectsAnalytics(projectsResponse.data)
          }
          if (proposalsResponse?.data) {
            setProposalAnalytics(proposalsResponse.data)
          }
        }
      } catch (error) {
        setError('Failed to load dashboard data')
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }
    getDashboardData()
  }, [token])

  const studentDashboardLinks: Array<PageLink> = [
    {title: 'Home', path: '/', isSeparator: false, isActive: false},
    {title: 'Dashboard', path: '/dashboard', isSeparator: true, isActive: false},
  ]

  return (
    <>
      <PageTitle breadcrumbs={studentDashboardLinks}>Dashboard</PageTitle>
      {isLoading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <Dashboard projectsAnalytics={projectsAnalytics} proposalAnalytics={proposalAnalytics} />
      )}
    </>
  )
}

export {StudentDashboard, ProjectsAnalytics}
