/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {selectToken} from '../../../../redux/selectors/auth'
import get from '../../../../lib/get'
import User from '../../../../types/User'
import {PageLink} from '../../../../_metronic/layout/core'
import {TProject} from '../../../../types/Project'
import put from '../../../../lib/put'
import {relative} from 'path'

type propType = {
  setViewDoc: Function
  viewDoc: TProject
  headerRef?: {headerTop: {current: HTMLDivElement}; headerBottom: {current: HTMLDivElement}}
}

const StudentOverviewHeader = ({setViewDoc, headerRef, viewDoc}: propType) => {
  const token = useSelector(selectToken)
  const {studentId, projectId} = useParams()
  const [student, setStudent] = useState<User | null>(null)

  const [project, setProject] = useState<TProject | null>(null)
  const [isLoading, setisLoading] = useState<boolean>(false)

  const [actionDrpDwn, setActionDrpDwn] = useState<boolean>(false)

  const assignedStudentBreadcrumbs: Array<PageLink> = [
    {
      title: 'Assigned Student',
      path: '/students/assignedStudent',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  const getProject = async () => {
    setisLoading(true)

    const RESPONSE = await get(`projects/${projectId}`, token)
    if (RESPONSE?.data) {
      setProject(RESPONSE.data)
    }

    setisLoading(false)
  }

  const handleMovefile = async (newState: any, file: any) => {
    console.log(newState, file)
    const res = await put(`files/${file._id}`, {state: newState}, token, true, 'State Updated')
    setViewDoc(res.data)
    getProject()

    setActionDrpDwn(!actionDrpDwn)
  }

  useEffect(() => {
    setisLoading(true)
    const getStudent = async () => {
      setisLoading(true)
      const RESPONSE = await get(`users/${studentId}`, token)
      if (RESPONSE?.data) setStudent(RESPONSE.data)
    }

    setisLoading(true)
    getStudent()
    getProject()
    setisLoading(false)
  }, [token, studentId, projectId])

  return (
    <>
      {/* <PageTitle breadcrumbs={assignedStudentBreadcrumbs}>View Project </PageTitle> */}

      <div style={{position: 'relative'}} className='card mb-5 mb-xl-10'>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            style={{position: 'absolute', right: 25, top: 15}}
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>{' '}
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-200px'
            data-kt-menu='true'
          >
            <div className='menu-item px-3'>
              <div className='menu-content fs-6 text-dark fw-bold px-3 py-4'>Quick Actions</div>
            </div>
            <div className='separator mb-3 opacity-75'></div>

            <div className='menu-item px-3'>
              {project?.workflow.states.map((state) => {
                if (viewDoc?.status?._id && viewDoc?.status?._id !== state._id) {
                  return (
                    <button
                      onClick={() => handleMovefile(state._id, viewDoc)}
                      key={state._id}
                      className='btn px-3 btn-active-light-primary'
                    >
                      Move to {state.title}
                    </button>
                  )
                }
              })}
            </div>

            <div className='separator mt-3 opacity-75'></div>
          </div>{' '}
          {/* )} */}
        </div>
        <div
          className='card-header border-0  cursor-pointer d-flex justify-content-between'
          role='button'
          ref={headerRef.headerTop}
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
          style={{marginRight: 70, paddingRight: 80}}
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Project Workflow</h3>
          </div>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>{viewDoc?.title}</h3>
          </div>
        </div>

        <div
          ref={headerRef.headerBottom}
          id='kt_account_profile_details'
          className='collapse show border-top pt-2 border-gray-400'
        >
          {isLoading && (
            <div className='fv-row d-flex justify-content-center mh-300px'>
              <div className='h-40px w-40px spinner-border spinner-border-sm align-middle ms-2'></div>
            </div>
          )}

          <div className='card-body'>
            <div className='row'>
              {project?.workflow?.states.map((state) => (
                <div key={state._id} className='col-2  border-end border-gray-400'>
                  <div
                    style={{backgroundColor: state.color}}
                    className={`d-flex flex-stack fs-4 py-3 px-2 `}
                  >
                    <div
                      className='fw-bold rotate cursor-pointer'
                      data-bs-toggle='collapse'
                      role='button'
                      aria-expanded='false'
                      data-bs-target={`#${state._id}`}
                      aria-controls={`${state._id}`}
                    >
                      {state.title}
                      <span className='ms-2 rotate-180'>
                        <i className='ki-duotone ki-down fs-3'></i>
                      </span>
                    </div>
                  </div>
                  <div className='separator separator-dashed my-1'></div>
                  <div id={`${state._id}`} className='collapse show '>
                    <li className='d-flex flex-column align-items-start py-2 '>
                      {project?.files.filter(
                        (file) =>
                          file?.status?._id === state?._id ||
                          (!file.status && state.position === '-1')
                      ).length > 0 ? (
                        project?.files
                          .filter((file) => {
                            if (!file.status && state.position === '-1') {
                              return !file.status
                            } else return file?.status?._id === state?._id
                          })
                          .map((file) => (
                            <button
                              key={file._id}
                              style={{
                                textAlign: 'left',
                                cursor: 'pointer',
                                backgroundColor: 'transparent',
                                border: 'none',
                              }}
                              onClick={() => {
                                setViewDoc(file)
                              }}
                              className='fs-6 text-gray-700 text-hover-primary fw-bold mb-1 d-flex align-items-center  left'
                            >
                              <span className='bullet me-5'></span> {file.title}
                            </button>
                          ))
                      ) : (
                        <span
                          style={{cursor: 'pointer'}}
                          className='fs-3  text-gray-500  text-center text-hover-primary fw-bold mb-1'
                        >
                          No Documents
                        </span>
                      )}
                    </li>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {!project && !isLoading && (
            <div className='fv-row d-flex justify-content-center mh-300px fs-5 py-20'>
              <span className='text-muted'> No Project</span>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default StudentOverviewHeader
