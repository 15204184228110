import React from 'react'

type Props = {}
interface CardProps {
  title: string
  count: number
  icon: string
  subtitle: string
}

const DashboardCard: React.FC<CardProps> = ({title, count, icon, subtitle}) => (
  <>
    <div className='symbol symbol-30px me-5 mb-8'>
      <span className='symbol-label'>
        <i className={`ki-duotone ${icon} fs-1 text-primary`}>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
      </span>
    </div>
    <div className='m-0'>
      <span className='text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>{count}</span>
      <span className='text-gray-500 fw-semibold fs-7'>{subtitle}</span>
    </div>
  </>
)
const StudentProjectsStats = ({projectsAnalytics}: any) => {
  return (
    <div className='h-50'>
      <div className='card card-stretch pt-6 scroll-y p-5'>
        <div className='row g-3'>
          <div className='col-xl-6 '>
            <div className='card-header h-150px  px-0'>
              <h3 className='card-title align-items-start fw-bold'>Projects</h3>
            </div>
            <div className='card-body mt-n20  px-0'>
              <div className='mt-n20 position-relative'>
                <div className='bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5'>
                  <div className='row g-3 g-lg-6'>
                    <div className='col-6'>
                      <DashboardCard
                        title='Ongoing Projects'
                        count={projectsAnalytics?.ongoingProjects || 0}
                        icon='ki-notepad-edit'
                        subtitle='Ongoing'
                      />
                    </div>
                    <div className='col-6'>
                      <DashboardCard
                        title='Approved Projects'
                        count={projectsAnalytics?.approvedProjects || 0}
                        icon='ki-questionnaire-tablet'
                        subtitle='Approved'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-6 '>
            <div className='card-header h-150px  px-0'>
              <h3 className='card-title align-items-start fw-bold'>Proposals</h3>
            </div>
            <div className='card-body mt-n20 px-0'>
              <div className='mt-n20 position-relative'>
                <div className='bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5'>
                  <div className='row g-3 g-lg-6'>
                    <div className='col-6'>
                      <DashboardCard
                        title='Approved Proposals'
                        count={projectsAnalytics?.approvedProposals || 0}
                        icon='ki-questionnaire-tablet'
                        subtitle='Approved'
                      />
                    </div>
                    <div className='col-6'>
                      <DashboardCard
                        title='Pending Proposals'
                        count={projectsAnalytics?.pendingProposals || 0}
                        icon='ki-subtitle'
                        subtitle='Pending'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StudentProjectsStats
