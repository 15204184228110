import {WebViewerInstance} from '@pdftron/webviewer'

import React, {useEffect, useRef, useState} from 'react'

import {
  PdfViewerComponent,
  Toolbar,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  Annotation,
  TextSearch,
  FormFields,
  FormDesigner,
  Inject,
} from '@syncfusion/ej2-react-pdfviewer'
import {registerLicense} from '@syncfusion/ej2-base'
import post from '../lib/post'
import {useSelector} from 'react-redux'
import {selectToken, selectUser} from '../redux/selectors/auth'
import TFile from '../types/File'
import get from '../lib/get'
import WordViewer from './wordViewer'
import PdfViewer from './pdfViewer'

type PropeType = {
  file?: TFile
  headerRef?: {headerTop: {current: HTMLDivElement}; headerBottom: {current: HTMLDivElement}}
}

const FileViewer = ({file, headerRef}: PropeType) => {
  return (
    <div>
      {file.type === 'pdf' ? (
        <PdfViewer headerRef={headerRef} file={file} />
      ) : (
        <WordViewer headerRef={headerRef} file={file} />
      )}
    </div>
  )
}

export default FileViewer
