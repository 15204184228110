import {WebViewerInstance} from '@pdftron/webviewer'

import React, {useEffect, useRef, useState} from 'react'

import {
  PdfViewerComponent,
  Toolbar,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  Annotation,
  TextSearch,
  FormFields,
  FormDesigner,
  Inject,
} from '@syncfusion/ej2-react-pdfviewer'
import {registerLicense} from '@syncfusion/ej2-base'
import post from '../lib/post'
import {useSelector} from 'react-redux'
import {selectToken, selectUser} from '../redux/selectors/auth'
import TFile from '../types/File'
import get from '../lib/get'

type PropeType = {
  file?: TFile
  headerRef?: {headerTop: {current: HTMLDivElement}; headerBottom: {current: HTMLDivElement}}
}

const PdfViewer = ({file, headerRef}: PropeType) => {
  let v: any
  const token = useSelector(selectToken)
  const [annotations, setAnnotations] = useState<any>([])

  const [viewer, setViewer] = useState<PdfViewerComponent>()
  const viewerRef = useRef(null)

  const currentUser = useSelector(selectUser)

  const updateAnnotations = async (annotations: string) => {
    await post(`annotations/${file._id}`, {data: annotations, file: file._id}, token, false)
  }

  useEffect(() => {
    setAnnotations([])

    const getAnnotations = async () => {
      try {
        if (token) {
          const RESPONSE = await get(`annotations/${file._id}`, token)

          if (RESPONSE?.data) {
            const res = RESPONSE?.data
            const updatedAnnotations = JSON.parse(`${res.data}`)

            if (updatedAnnotations.pdfAnnotation && viewerRef.current) {
              setAnnotations(updatedAnnotations)
              viewerRef.current.importAnnotation(updatedAnnotations)
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    }

    if (headerRef?.headerBottom?.current) {
      headerRef?.headerBottom?.current.classList.remove('show')
      headerRef?.headerBottom?.current.classList.add('collapsing')
      headerRef?.headerBottom?.current.classList.add('collapse')
      headerRef?.headerBottom?.current.classList.remove('collapsing')
    }
    if (headerRef?.headerTop?.current) {
      headerRef?.headerTop?.current.classList.add('collapsed')
      headerRef.headerTop.current.ariaExpanded = 'false'
    }

    getAnnotations()
  }, [token, file])

  useEffect(() => {
    try {
      if (annotations?.pdfAnnotation && viewerRef?.current) {
        viewerRef.current.importAnnotation(annotations)
      }
    } catch (error) {
      console.log(error)
    }
  }, [annotations])

  const onAnnotationAdd = async (annotation: any) => {
    const newAllAnnotations: any = await viewerRef?.current?.exportAnnotationsAsObject()
    updateAnnotations(newAllAnnotations)
  }

  const onDocumentLoad = () => {
    if (annotations.pdfAnnotation && viewerRef.current) {
      console.log(annotations)
      viewerRef.current.importAnnotation(annotations)
    }
  }
  return (
    <div>
      {file ? (
        <div className='control-section'>
          <PdfViewerComponent
            id='container'
            ref={viewerRef}
            annotationAdd={onAnnotationAdd}
            commentStatusChanged={onAnnotationAdd}
            commentDelete={onAnnotationAdd}
            commentEdit={onAnnotationAdd}
            commentAdd={onAnnotationAdd}
            documentPath={file?.path}
            documentLoad={onDocumentLoad}
            annotationSettings={{
              author: currentUser.lastName + ' ' + currentUser.firstName,
            }}
            toolbarSettings={{
              showTooltip: true,
              toolbarItems: [
                'UndoRedoTool',
                'PageNavigationTool',
                'MagnificationTool',
                'PanTool',
                'SelectionTool',
                'CommentTool',
                'AnnotationEditTool',
                'SearchOption',
                'PrintOption',
                'DownloadOption',
              ],
              annotationToolbarItems: [
                'HighlightTool',
                'UnderlineTool',
                'StrikethroughTool',
                'ColorEditTool',
                'OpacityEditTool',
                'AnnotationDeleteTool',
                'InkAnnotationTool',
                'ShapeTool',
                'StrokeColorEditTool',
                'ThicknessEditTool',
                'FreeTextAnnotationTool',
                'FontFamilyAnnotationTool',
                'FontSizeAnnotationTool',
                'FontStylesAnnotationTool',
                'FontAlignAnnotationTool',
                'FontColorAnnotationTool',
                'CommentPanelTool',
              ],
            }}
            isCommandPanelOpen={true}
            resourceUrl='https://cdn.syncfusion.com/ej2/23.1.40/dist/ej2-pdfviewer-lib'
            style={{height: '640px'}}
          >
            <Inject
              services={[
                Toolbar,
                Annotation,
                LinkAnnotation,
                BookmarkView,
                ThumbnailView,
                Print,
                TextSelection,
                TextSearch,
                FormFields,
                FormDesigner,
              ]}
            />
          </PdfViewerComponent>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default PdfViewer
