import {UserEditModalForm} from './UserEditModalForm'
import {useEffect} from 'react'

const UserEditModalFormWrapper = ({state}: {state: any}) => {
  const {itemIdForUpdate, isLoading, user, error} = state

  useEffect(() => {}, [user])

  if (!itemIdForUpdate) {
    return <UserEditModalForm state={state} />
  }
  if (!isLoading && !error && user) {
    return <UserEditModalForm state={state} />
  }

  return null
}

export {UserEditModalFormWrapper}
