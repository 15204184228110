import {useEffect, useState} from 'react'
import {KTIcon} from '../../_metronic/helpers'
import {useSelector} from 'react-redux'
import {selectToken} from '../../redux/selectors/auth'
import * as swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import put from '../../lib/put'
import {TProject} from '../../types/Project'
import ProjectPDF from '../../pdf-export/project-details'
import {PDFDownloadLink} from '@react-pdf/renderer'
import User from '../../types/User'
import get from '../../lib/get'
import TWorkflow from '../../types/Workflow'
import {useNavigate} from 'react-router-dom'

const MySwal = withReactContent(swal.default)

const initialValues = {
  title: '',
  workflow: '',
  supervisor: '',
  dueDate: '',
  student: '',
  programme: '',
  description: '',
  degree: '',
}

type PropTypes = {
  project: TProject
  refreshProject: Function
}
const EditProjectSchema = Yup.object().shape({
  title: Yup.string().required('Project Title is required'),
  description: Yup.string().required('Project Description is required'),
})

const EditProject = ({project, refreshProject}: PropTypes) => {
  const [IsLoading, setIsLoading] = useState<boolean>(false)
  const [workflows, setWorkflows] = useState<Array<TWorkflow>>()
  const [supervisorDrpdw, setSupervisorDrpdw] = useState<boolean>(false)
  const [selectedSupervisors, selectSupervisor] = useState<Array<User>>(project?.supervisors)
  const navigate = useNavigate()
  const [supervisors, setSupervisors] = useState<Array<User>>()

  const token = useSelector(selectToken)

  const toggleSupDrdw = () => {
    setSupervisorDrpdw(!supervisorDrpdw)
  }

  const removeSupervisor = (supervisor: User) => {
    selectSupervisor(selectedSupervisors.filter((sup) => sup._id !== supervisor._id))
  }

  const handleSetSupervisor = (supervisor: User) => {
    if (!selectedSupervisors.find((sup) => sup._id === supervisor._id)) {
      selectSupervisor([supervisor, ...selectedSupervisors])
    }

    setSupervisorDrpdw(false)
  }

  const formik = useFormik({
    initialValues: {...initialValues, ...project},
    validationSchema: EditProjectSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoading(true)
      try {
        console.log(values)
        await put(
          `projects/${project._id}`,
          {...values, supervisors: selectedSupervisors},
          token,
          true,
          'Project Updated'
        )

        setSubmitting(false)
        setIsLoading(false)
      } catch (error: any) {
        console.log(error)
        setSubmitting(false)
        setIsLoading(false)

        if (error.response?.data.message) {
          return setStatus(error.response.data.message)
        }
        if (error.response?.data.error) {
          return setStatus(error.response.data.error)
        } else {
          return setStatus(error.error)
        }
      } finally {
        navigate(-1)
      }
    },
  })

  useEffect(() => {
    const getSupervisors = async () => {
      const RESPONSE = await get(`users/supervisors`, token)
      if (RESPONSE.data) {
        setSupervisors(RESPONSE.data)
      }
    }
    const getWorkflow = async () => {
      const RESPONSE = await get(`workflows`, token)
      if (RESPONSE.data) {
        setWorkflows(RESPONSE.data)
      }
    }

    getWorkflow()
    formik.setFieldValue('workflow', project?.workflow?._id)
    formik.setFieldValue('dueDate', project?.dueDate?.slice(0, 16))
    getSupervisors()
  }, [])

  return (
    <>
      <div className='card'>
        <div className='card-header'>
          <div className='card-title fs-3 fw-bold'>Update Project</div>
          {/* <div className='card-toolbar'>
            <PDFDownloadLink
              document={<ProjectPDF project={project} />}
              fileName={`${project.title.split(' ').join('_')}_details.pdf`}
            >
              {({blob, url, loading, error}) =>
                loading ? (
                  'Loading document...'
                ) : (
                  <button className='btn btn-sm btn-light-primary'>
                    <KTIcon iconName='file-down' className='fs-2' />
                    Export
                  </button>
                )
              }
            </PDFDownloadLink>
          </div> */}
        </div>

        <form
          className='form w-100 h-100'
          onSubmit={formik.handleSubmit}
          noValidate
          id='update_project_form'
        >
          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            ''
          )}

          <div className='card-body p-9'>
            <div className='row mb-8'>
              <div className='col-lg-6'>
                <div className='row'>
                  <div className='col-xl-3'>
                    <div className='fs-6 fw-semibold mt-2 mb-3'>Project Author</div>
                  </div>
                  <div className='col-xl-9 fv-row fv-plugins-icon-container'>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      readOnly={true}
                      value={project.student.lastName + ' ' + project.student.firstName}
                    />
                    <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='row'>
                  <div className='col-xl-3'>
                    <div className='fs-6 fw-semibold mt-2 mb-3'>Project Title</div>
                  </div>
                  <div className='col-xl-9 fv-row fv-plugins-icon-container'>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      {...formik.getFieldProps('title')}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.title}</span>
                        </div>
                      </div>
                    )}
                    <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mb-8'>
              <div className='col-lg-6'>
                <div className='row'>
                  <div className='col-xl-3'>
                    <div className='fs-6 fw-semibold mt-2 mb-3'>Project Description</div>
                  </div>
                  <div className='col-xl-9 fv-row fv-plugins-icon-container'>
                    <textarea
                      {...formik.getFieldProps('description')}
                      className='form-control form-control-solid h-100px'
                    >
                      {project?.description}
                    </textarea>
                    {formik.touched.description && formik.errors.description && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.description}</span>
                        </div>
                      </div>
                    )}
                    <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='row'>
                  <div className='col-xl-3'>
                    <div className='fs-6 fw-semibold mt-2 mb-3'> Workflow</div>
                  </div>
                  <div className='col-xl-9 fv-row fv-plugins-icon-container'>
                    {workflows?.length > 0 && (
                      <select
                        {...formik.getFieldProps('workflow')}
                        className='form-control form-control-solid'
                      >
                        <option value=''>Select Workflow</option>

                        {workflows?.map((workflow) => (
                          <option key={workflow._id} value={workflow._id}>
                            {workflow.title}
                          </option>
                        ))}
                      </select>
                    )}
                    {formik.touched.workflow && formik.errors.workflow && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.workflow}</span>
                        </div>
                      </div>
                    )}
                    <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-6'>
                <div className='row'>
                  <div className='col-xl-3'>
                    <div className='fs-6 fw-semibold mt-2 mb-3'> Due date </div>
                  </div>
                  <div className='col-xl-9 fv-row fv-plugins-icon-container'>
                    <input
                      {...formik.getFieldProps('dueDate')}
                      // defaultValue={project?.dueDate?.slice(0, 16)}
                      className='form-control'
                      placeholder='Enter start date and time'
                      type='datetime-local'
                    />
                    {formik.touched.dueDate && formik.errors.dueDate && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.dueDate}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='fs-6 fw-semibold mt-2 mb-3'> Supervisors</div>
              <div className='col-xl-12 fv-row fv-plugins-icon-container'>
                <div className='' style={{position: 'relative'}}>
                  <div
                    onClick={() => toggleSupDrdw()}
                    className='form-control form-control-solid p-5 d-flex gap-3'
                  >
                    {selectedSupervisors.length > 0
                      ? selectedSupervisors.map((sup: User) => (
                          <div
                            style={{position: 'relative'}}
                            className='w-150px rounded d-flex flex-column align-items-center bg-light p-4'
                          >
                            <div
                              className='btn btn-icon absolute btn-sm btn-active-icon-primary'
                              data-kt-users-modal-action='close'
                              onClick={() => removeSupervisor(sup)}
                              style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                top: 5,
                                right: 2,
                              }}
                            >
                              <KTIcon iconName='cross' className='fs-1 text-primary' />
                            </div>
                            <div className='symbol symbol-40px symbol-circle'>
                              <span className='symbol-label fs-5 bg-secondary text-inverse-secondary fw-bold'>
                                {sup?.lastName[0]}
                              </span>
                            </div>
                            <div className='ms-4'>
                              <span className='fs-6 fw-bold text-gray-900 text-hover-primary mb-2'>
                                {sup.lastName + ' ' + sup.firstName}
                              </span>
                              <div className='fw-semibold fs-7 text-muted'>{sup.email}</div>
                            </div>
                          </div>
                        ))
                      : 'Select a supervisor'}
                  </div>
                  {supervisorDrpdw && (
                    <div
                      style={{position: 'absolute'}}
                      className='card card-flush'
                      id='kt_contacts_list'
                    >
                      <div className='card-header pt-7' id='kt_contacts_list_header'>
                        <div className='row'>
                          <div className='d-flex align-items-center position-relative w-100 m-0'>
                            <div>
                              <i className='ki-duotone ki-magnifier fs-3 text-gray-500 position-absolute top-50 ms-5 translate-middle-y'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                              <input
                                type='text'
                                className='form-control form-control-solid ps-13'
                                name='search'
                                defaultValue=''
                                placeholder='Search supervisors'
                              />
                            </div>
                            <div
                              className='btn btn-icon btn-sm btn-active-icon-primary'
                              data-kt-users-modal-action='close'
                              onClick={() => toggleSupDrdw()}
                              style={{cursor: 'pointer'}}
                            >
                              <KTIcon iconName='cross' className='fs-1' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='card-body pt-5' id='kt_contacts_list_body'>
                        <div
                          className='scroll-y me-n5 pe-5 h-300px h-xl-auto'
                          data-kt-scroll='true'
                          data-kt-scroll-activate='{default: false, lg: true}'
                          data-kt-scroll-max-height='auto'
                          data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_contacts_list_header'
                          data-kt-scroll-wrappers='#kt_content, #kt_contacts_list_body'
                          data-kt-scroll-stretch='#kt_contacts_list, #kt_contacts_main'
                          data-kt-scroll-offset='5px'
                          style={{maxHeight: '763px'}}
                        >
                          {supervisors.length > 0 &&
                            supervisors &&
                            !IsLoading &&
                            supervisors.map((supervisor) => (
                              <div
                                key={supervisor._id}
                                className='d-flex  flex-stack py-4 btn btn-active-light-info'
                                onClick={() => handleSetSupervisor(supervisor)}
                              >
                                <div className='d-flex align-items-center '>
                                  <div className='symbol symbol-40px symbol-circle'>
                                    <span className='symbol-label bg-secondary text-inverse-secondary fw-bold'>
                                      {supervisor?.lastName[0]}
                                    </span>
                                  </div>
                                  <div className='ms-4'>
                                    <span className='fs-6 fw-bold text-gray-900 text-hover-primary mb-2'>
                                      {supervisor.lastName + ' ' + supervisor.firstName}
                                    </span>
                                    <div className='fw-semibold fs-7 text-muted'>
                                      {supervisor.email}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          {IsLoading && (
                            <div className='fv-row d-flex justify-content-center mh-300px'>
                              <div className='h-40px w-40px spinner-border spinner-border-sm align-middle ms-2'></div>
                            </div>
                          )}
                          <div className='separator separator-dashed d-none'></div>
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedSupervisors.length < 1 && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>Supervisor is required</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
              </div>
            </div>
          </div>
          <div className='card-footer'>
            <div className='row'>
              <div className='col-6'>
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-primary'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!IsLoading && <span className='indicator-label'>Update</span>}
                  {IsLoading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
          <input type='hidden' />
        </form>
      </div>
    </>
  )
}

export default EditProject
