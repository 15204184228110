/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid' style={{minHeight: '100vh'}}>
      {/* begin::Body */}
      <div className='d-flex card flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px w-100 mw-500px  p-10'>
            <div className='d-flex justify-content-center align-items-center mb-5'>
              <div className='separator my-2 '></div>
              <Link to='/' className=''>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/skorler_new_logo.png')}
                  className='h-55px'
                />
              </Link>
              <div className='separator my-2 '></div>
            </div>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5'>
          {/* begin::Links */}
          <div className='d-flex fw-semibold text-primary fs-base'>
            <a href='#' className='px-5' target='_blank'>
              Terms
            </a>
            <a href='#' className='px-5' target='_blank'>
              Contact Us
            </a>
          </div>
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-100 h-100vh w-lg-50 bgi-size-cover justify-content-center align-items-center bgi-position-center order-1 order-lg-2'
        style={{
          backgroundColor: '#d5ede8',
        }}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column mw-lg-100 mw-500px mw-lg-auto justify-content-center align-items-center py-10 px-5 px-md-10 w-100 '>
          {/* begin::Logo */}

          {/* end::Logo */}

          {/* begin::Title */}
          <p
            style={{lineHeight: 1}}
            className='text-black fw-bolder w-100 text-center text-lg-start  fs-3hx  px-md-10 mb-7'
          >
            Transformation is here, <span style={{color: '#38c68b'}}>now.</span>
          </p>
          <p className='fs-2  w-100 text-lg-start  text-center mb-7 px-md-10'>
            Skorler is changing the way students, professors, writers, readers, editors and
            reviewers collaborate
          </p>
          {/* end::Title */}

          {/* begin::Text */}
          <div className='text-black fs-base text-center'>
            {/* Unlock the future of academic research with SuperVised™, the platform designed to
            revolutionize the way students and advisors collaborate. Experience real-time feedback,
            secure document management, and streamlined approval processes, all in one intuitive
            interface. Say goodbye to manual tracking and hello to a seamless research journey. Join
            us in the pursuit of knowledge, powered by technology." */}
          </div>
          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
