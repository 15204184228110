import React, {useEffect, useState} from 'react'
import TProgramme from '../../types/Programme'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import post from '../../lib/post'
import put from '../../lib/put'
import {useSelector} from 'react-redux'
import {selectToken} from '../../redux/selectors/auth'
import {KTIcon} from '../../_metronic/helpers'
import TState from '../../types/States'
import get from '../../lib/get'
import Department from '../../types/Department'
import clsx from 'clsx'

type PropTypes = {
  currentProgramme: TProgramme
  setCurrentProgramme: Function
  refreshProgramme: Function
}

const initialValues = {
  name: '',
  department: '',
}

const editProgrammeSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  department: Yup.string().required('Programme department is required'),

  // description: Yup.string().required('Project Description is required'),
})

const EditWorkFlow = ({currentProgramme, setCurrentProgramme, refreshProgramme}: PropTypes) => {
  const token = useSelector(selectToken)
  const [IsLoading, setIsLoading] = useState<boolean>(false)
  const [IsStateLoading, setIsStateLoading] = useState<boolean>(false)
  const [departments, setDepartments] = useState<Array<Department>>([])

  const formik = useFormik({
    initialValues: currentProgramme,
    validationSchema: editProgrammeSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoading(true)
      try {
        const RESPONSE: any = await put(
          `programmes/${currentProgramme._id}`,
          {...values},
          token,
          true,
          'Programme Updated'
        )
        await refreshProgramme()
        setSubmitting(false)
        setIsLoading(false)
      } catch (error: any) {
        console.log(error)
        setSubmitting(false)
        setIsLoading(false)

        if (error.response?.data.message) {
          return setStatus(error.response.data.message)
        }
        if (error.response?.data.error) {
          return setStatus(error.response.data.error)
        } else {
          return setStatus(error.error)
        }
      }
    },
  })

  const getDepartments = async () => {
    if (token) {
      const RESPONSE = await get('departments', token)
      setDepartments(RESPONSE.data)
    }
  }
  useEffect(() => {
    getDepartments()
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            {/* end::Modal Backdrop */}

            <div className='modal-header pt-7' id='kt_chat_contacts_header'>
              <div className='modal-title'>
                <h2> Edit programme</h2>
              </div>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                style={{cursor: 'pointer'}}
                onClick={() => setCurrentProgramme(undefined)}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>
            {/* begin::Modal body */}

            <div className='modal-body scroll-y mx-5 mx-xl-10 '>
              <div className='card-body'>
                <form
                  className='form w-100 h-100'
                  onSubmit={formik.handleSubmit}
                  noValidate
                  id='workflow_modification_form'
                >
                  <div className='row mb-7 fv-plugins-icon-container'>
                    <label className='fs-6 fw-semibold form-label mt-3'>
                      <span className='required'>Title</span>
                      <span
                        className='ms-1'
                        data-bs-toggle='tooltip'
                        aria-label="Enter the contact's name."
                        data-bs-original-title="Enter the contact's name."
                        data-kt-initialized='1'
                      >
                        <i className='ki-duotone ki-information fs-7'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                        </i>
                      </span>
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      name='name'
                      {...formik.getFieldProps('name')}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.name}</span>
                        </div>
                      </div>
                    )}
                    <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
                  </div>
                  <div className='row mb-7 fv-plugins-icon-container'>
                    <label className='fs-6 fw-semibold form-label mt-3'>
                      <span className='required'>Department</span>
                    </label>

                    <select
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.department && formik.errors.department},
                        {
                          'is-valid': formik.touched.department && !formik.errors.department,
                        }
                      )}
                      {...formik.getFieldProps('department')}
                      placeholder='Department'
                    >
                      <option value=''>Select Department</option>

                      {departments.map((e) => {
                        return (
                          <option key={e._id} value={e._id}>
                            {e.name}
                          </option>
                        )
                      })}
                    </select>

                    {/* end::Input */}
                    {formik.touched.department && formik.errors.department && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          {/* <span role='alert'>{formik.errors.department}</span> */}
                        </div>
                      </div>
                    )}
                    <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
                  </div>
                  <div className='row mb-7 fv-plugins-icon-container'>
                    <div className='d-flex justify-content-end'>
                      <button
                        type='reset'
                        data-kt-contacts-type='cancel'
                        className='btn btn-light me-3'
                        onClick={() => setCurrentProgramme(undefined)}
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        id='kt_sign_in_submit'
                        className='btn btn-primary'
                        disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {!IsLoading && <span className='indicator-label'>Update</span>}
                        {IsLoading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* end::Modal body */}
            {/* end::Modal content */}
          </div>
          {/* end::Modal dialog */}
        </div>
        {/* begin::Modal Backdrop */}
      </div>

      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default EditWorkFlow
