import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import User from '../../types/User'
import get from '../../lib/get'
import {useSelector} from 'react-redux'
import {selectAuth, selectUser} from '../../redux/selectors/auth'
import put from '../../lib/put'
import {getDownloadURL, ref, uploadBytes} from 'firebase/storage'
import {storage} from '../../utils/firebase'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import patch from '../../lib/patch'

const profileDetailsSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email().required('Email is required'),
  contactNumber: Yup.string().required('Contact Number is required'),
})
const passwordDetailsSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  newPassword: Yup.string().required('New Password  is required'),
  confirmNewPassword: Yup.string().required('Confirm Password is required'),
})

const initialValues = {
  createdAt: '',
  department: {_id: '', name: ''},
  email: '',
  avatar: '',
  firstName: '',
  contactNumber: '',
  lastName: '',
  password: '',
  updatedAt: '',
  _id: '',
  notification: {
    email: false,
  },
}
const EditAccount: React.FC = () => {
  const [data, setData] = useState<User>()
  const [profile, setProfile] = useState<User>()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [passwordUpdateIsLoading, SetpasswordUpdateIsLoading] = useState<boolean>(false)
  const [newProfile, setNewProfile] = useState<File>()
  const [isSubmitting, setSubmitting] = useState<boolean>(true)
  const {token} = useSelector(selectAuth)
  const currentUser = useSelector(selectUser)

  const isAdmin = currentUser?.roles.some((role) => role._id === '651c8fab03a0295e6de56b99')

  const updateData = (fieldsToUpdate: Partial<User>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }

  useEffect(() => {
    const getProfile = async () => {
      setIsLoading(true)
      try {
        if (token) {
          const RESPONSE = await get('users/myProfile/view', token)
          setProfile(RESPONSE.data)

          formik.values.firstName = RESPONSE.data?.firstName
          formik.values.lastName = RESPONSE.data?.lastName
          formik.values.email = RESPONSE.data?.email
          formik.values.contactNumber = RESPONSE.data?.contactNumber
          formik.values.notification = RESPONSE.data?.notification

          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
        console.log(error)
      }
    }

    getProfile()
  }, [token])

  const updateProfileImage = (e: any) => {
    const file = e.target.files[0]

    if (file) {
      setNewProfile(file)
    }
  }

  const hadlefileProfileUpload = async (userId: string, image: any) => {
    const fileRefPathRef = ref(storage, `profiles/${profile._id}/profile`)
    await uploadBytes(fileRefPathRef, image).then((snapshot) => {})
    const path = await getDownloadURL(fileRefPathRef)
    return path
  }

  const [loading, setLoading] = useState(false)

  const formik = useFormik<User>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true)

      try {
        let profilePath
        if (newProfile) {
          profilePath = await hadlefileProfileUpload(values._id, newProfile)
        }

        const RESPONSE: any = await put(
          'users/myProfile/update',
          {...values, ...(profilePath && {avatar: profilePath})},
          token,
          true,
          'Profile Updated'
        )
        if (1) {
          formik.values = initialValues
        }
        setSubmitting(false)
        setLoading(false)
      } catch (error) {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const formikPasswordUpdate = useFormik<{
    password: string
    newPassword: string
    confirmNewPassword: string
  }>({
    initialValues: {password: '', confirmNewPassword: '', newPassword: ''},
    validationSchema: passwordDetailsSchema,
    onSubmit: async (values) => {
      SetpasswordUpdateIsLoading(true)

      if (values.newPassword !== values.confirmNewPassword) {
        Swal.fire({
          title: `Invalid Password Input`,
          text: `Passwords dont match`,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok!',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          heightAuto: false,
        })
        SetpasswordUpdateIsLoading(false)
        return
      }
      try {
        const data = await patch(
          'auth/change-password',
          {...values},
          token,
          true,
          'Password Updated'
        )

        if (1) {
          formik.values = initialValues
        }

        setSubmitting(false)
        SetpasswordUpdateIsLoading(false)
      } catch (error) {
        console.log(error.response)
        setSubmitting(false)
        SetpasswordUpdateIsLoading(false)
      }
    },
  })

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Profile Details</h3>
          </div>
          <div className='card-toolbar d-flex gap-2'>
            <Link to='/account/overview' className='btn btn-sm btn-primary align-self-center '>
              Edit Profile
            </Link>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <div
                        className='image-input image-input-outline w-125px h-125px'
                        data-kt-image-input='true'
                        style={{
                          backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                        }}
                      >
                        <div
                          className='image-input-wrapper w-100 h-100'
                          style={{
                            backgroundImage: `url(${
                              profile?.avatar
                                ? profile?.avatar
                                : toAbsoluteUrl('/media/avatars/blank.png')
                            })`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                          }}
                        ></div>
                      </div>
                    </div>

                    <div className='col-lg-6 fv-row  py-10'>
                      <label className='col-lg-4 col-form-label  fw-bold fs-6'>
                        Update Profile
                      </label>
                      <div className='col-12'>
                        <input
                          type='file'
                          onChange={(e) => updateProfileImage(e)}
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Last name'
                        />
                        {formik.touched.avatar && formik.errors.avatar && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.avatar}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-2 col-form-label required fw-bold fs-6'>Full Name</label>

                <div className='col-lg-10'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='First name'
                        readOnly={!isAdmin}
                        {...formik.getFieldProps('firstName')}
                      />
                      {formik.touched.firstName && formik.errors.firstName && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.firstName}</div>
                        </div>
                      )}
                    </div>

                    <div className='col-lg-6 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Last name'
                        readOnly={!isAdmin}
                        {...formik.getFieldProps('lastName')}
                      />
                      {formik.touched.lastName && formik.errors.lastName && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.lastName}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-2 col-form-label required fw-bold fs-6'>Email</label>

                <div className='col-lg-10 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Email'
                    readOnly={!isAdmin}
                    {...formik.getFieldProps('email')}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.email}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-2 col-form-label required fw-bold fs-6'>
                  Notification
                </label>

                <div className='col-lg-10 fv-row'>
                  <div className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='inlineCheckbox3'
                      {...formik.getFieldProps('notification.email')}
                    />
                    <label className='form-check-label fw-bold' htmlFor='inlineCheckbox3'>
                      Email
                    </label>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-2 col-form-label fw-bold fs-6'>
                  <span className='required'>Contact Phone</span>
                </label>

                <div className='col-lg-10 fv-row'>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Phone number'
                    {...formik.getFieldProps('contactNumber')}
                  />
                  {formik.touched.contactNumber && formik.errors.contactNumber && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.contactNumber}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Change Password</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formikPasswordUpdate.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <div className='col-4'>
                  <label className='col-lg-12  col-form-label required fw-bold fs-6'>
                    Current Password
                  </label>
                  <div className='col-lg-12  fv-row'>
                    <input
                      type='password'
                      security={'true'}
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Current Password'
                      {...formikPasswordUpdate.getFieldProps('password')}
                    />
                    {formikPasswordUpdate.touched.password && formikPasswordUpdate.errors.password && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formikPasswordUpdate.errors.password}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-4'>
                  <label className='col-lg-12 col-form-label required fw-bold fs-6'>
                    New Password
                  </label>
                  <div className='col-lg-12 fv-row'>
                    <input
                      type='password'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='New Password'
                      {...formikPasswordUpdate.getFieldProps('newPassword')}
                    />
                    {formikPasswordUpdate.touched.newPassword &&
                      formikPasswordUpdate.errors.newPassword && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formikPasswordUpdate.errors.newPassword}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className='col-4'>
                  <label className='col-lg-12  col-form-label required fw-bold fs-6'>
                    Confirm New Password
                  </label>
                  <div className='col-lg-12  fv-row'>
                    <input
                      type='password'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Confirm New Password'
                      {...formikPasswordUpdate.getFieldProps('confirmNewPassword')}
                    />
                    {formikPasswordUpdate.touched.confirmNewPassword &&
                      formikPasswordUpdate.errors.confirmNewPassword && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formikPasswordUpdate.errors.confirmNewPassword}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={passwordUpdateIsLoading}>
                {!passwordUpdateIsLoading && 'Update'}
                {passwordUpdateIsLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default EditAccount
