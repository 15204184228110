import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../_metronic/layout/MasterLayout'
import {DashboardWrapper} from '../modules/dashboard/DashboardWrapper'
import BuilderPageWrapper from '../modules/layout-builder/BuilderPageWrapper'
import Users from '../modules/users/users'
import Roles from '../modules/users/roles'
import Permissions from '../modules/users/permissions'
import EditDocument from '../modules/documents/editDocument'
import AllSupervisors from '../modules/users/supervisors'
import AllAssignedStudents from '../modules/users/assignedStudents'
import ProjectPage from '../modules/project/student/projectpage'
import CreateProject from '../modules/project/createProject'
import MyProjects from '../modules/project/student/myProjects'
import AllProjects from '../modules/project/allProjects'
import AllStudentsProjects from '../modules/project/supervisor/allStudentProjects'
import AllAssignedProjects from '../modules/project/supervisor/allAssignedProjects'
import FileViewer from '../components/FileViewer'
import CreateProposal from '../modules/proposal/createProposal'
import MyProposals from '../modules/proposal/myProposals'
import SubmittedProposals from '../modules/proposal/submittedProposals'

import Proposal from '../modules/proposal/view-proposal/Proposal'
import AllStudents from '../modules/users/students'
import Workflow from '../modules/workflow/workflow'
import AccountOverview from '../modules/account/overview'
import EditAccount from '../modules/account/edit'
import HelpCenter from '../modules/help-center'
import ViewProject from '../modules/project/supervisor/view-project/view-project'
import Programme from '../modules/programmes/programmes'
import Degree from '../modules/degree/degrees'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />

        {/*  */}
        {/*  */}
        {/* Asset based routes */}
        {/* Asset based routes */}
        {/*  */}
        {/*  */}

        <Route path='/users'>
          <Route index element={<Navigate to='/users/all' />} />
          <Route path='all' element={<Users />} />
          <Route path='roles' element={<Roles />} />
          <Route path='permissions' element={<Permissions />} />
          <Route path='supervisors' element={<AllSupervisors />} />
          <Route path='students' element={<AllStudents />} />
        </Route>

        <Route path='/account'>
          <Route path='overview' element={<AccountOverview />} />
          <Route path='edit' element={<EditAccount />} />
        </Route>

        <Route path='/proposals'>
          <Route path='submit' element={<CreateProposal />} />
          <Route path='my' element={<MyProposals />} />
        </Route>

        <Route path='/facultyadmin'>
          <Route path='proposals'>
            <Route path='submitted' element={<SubmittedProposals />} />
            <Route path=':proposalId' element={<Proposal />} />
          </Route>
        </Route>

        <Route path='/project'>
          <Route path='my' element={<MyProjects />} />
          <Route path='all' element={<AllProjects />} />

          <Route path='create' element={<CreateProject />} />
          <Route path='assigned' element={<AllAssignedProjects />} />

          <Route path='edit/:documentID' element={<EditDocument />} />

          <Route path=':projectId' element={<ProjectPage />} />
        </Route>

        <Route path='students'>
          <Route path='assignedStudents'>
            <Route index element={<AllAssignedStudents />} />
            <Route path=':studentId' element={<AllStudentsProjects />} />
            <Route path=':studentId/project/:projectId' element={<ViewProject />}>
              <Route path=':fileId' element={<FileViewer />} />
            </Route>
            {/* <Route path='projects' element={<AssignedStudentProjects />} /> */}
          </Route>
        </Route>

        <Route
          path='*'
          element={
            <>
              <Navigate to='/error/404' />
            </>
          }
        />
        <Route path='workflow' element={<Workflow />} />
        <Route path='programme' element={<Programme />} />
        <Route path='degree' element={<Degree />} />
        <Route path='help-center' element={<HelpCenter />} />
      </Route>
    </Routes>
  )
}

// const SuspensedView: FC<WithChildren> = ({children}) => {
//   const baseColor = getCSSVariableValue('--bs-primary')
//   TopBarProgress.config({
//     barColors: {
//       '0': baseColor,
//     },
//     barThickness: 1,
//     shadowBlur: 5,
//   })
//   return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
// }

export {PrivateRoutes}
