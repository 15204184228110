import React, {useEffect, useRef} from 'react'
import {
  DocumentEditorContainerComponent,
  Toolbar,
  Editor,
  WordExport,
  SfdtExport,
  Print,
  TextExport,
  Inject,
} from '@syncfusion/ej2-react-documenteditor'
import {DialogUtility} from '@syncfusion/ej2-popups'
import {useSelector} from 'react-redux'
import {selectToken} from '../redux/selectors/auth'
import TFile from '../types/File'
import {registerLicense} from '@syncfusion/ej2-base'

DocumentEditorContainerComponent.Inject(Toolbar, Editor, WordExport, SfdtExport, Print, TextExport)

const CommentsComponent = ({file, headerRef}: any) => {
  const token = useSelector(selectToken)
  const containerRef = useRef<DocumentEditorContainerComponent>(null)

  useEffect(() => {
    if (file) {
      renderComplete()
      if (headerRef?.headerBottom?.current) {
        headerRef?.headerBottom?.current.classList.remove('show')
        headerRef?.headerBottom?.current.classList.add('collapsing')
        headerRef?.headerBottom?.current.classList.add('collapse')
        headerRef?.headerBottom?.current.classList.remove('collapsing')
      }
      if (headerRef?.headerTop?.current) {
        headerRef?.headerTop?.current.classList.add('collapsed')
        headerRef.headerTop.current.ariaExpanded = 'false'
      }
    }
  }, [file])

  const hostUrl = 'https://ej2services.syncfusion.com/production/web-services/api/documenteditor/'

  const settings = {
    showRuler: true,
    enableComment: true,
  }

  const onLoadDefault = () => {
    if (containerRef.current) {
      containerRef.current.documentEditor.documentName = file?.title
      containerRef.current.documentEditor.open(file?.path)
      containerRef.current.documentEditor.showComments = true
      containerRef.current.documentEditorSettings.showRuler = true

      containerRef.current.documentChange = () => {
        containerRef.current.documentEditor.focusIn()
      }

      containerRef.current.commentDelete = (args: any) => {
        if (args.author !== containerRef.current.documentEditor.currentUser) {
          args.cancel = true
          DialogUtility.alert({
            title: 'Information',
            content: 'Delete restriction enabled. Only the author of the comment can delete it.',
            showCloseIcon: true,
            closeOnEscape: true,
          })
        }
      }
    }
  }

  const renderComplete = () => {
    window.onbeforeunload = () => 'Want to save your changes?'
    if (containerRef.current) {
      containerRef.current.documentEditor.pageOutline = '#E0E0E0'
      containerRef.current.documentEditor.acceptTab = true
      containerRef.current.documentEditor.resize()
      onLoadDefault()
    }
  }

  return (
    <div className='control-pane'>
      <div className='control-section'>
        <div id='documenteditor_titlebar' className='e-de-ctn-title'></div>
        <div id='documenteditor_container_body'>
          <DocumentEditorContainerComponent
            id='container'
            ref={containerRef}
            style={{display: 'block'}}
            height={'590px'}
            serviceUrl={hostUrl}
            enableToolbar={true}
            showPropertiesPane={false}
            locale='en-US'
            userColor='#b70f34'
            currentUser='Nancy Davolio'
            documentEditorSettings={settings}
          >
            <Inject services={[Toolbar]} />
          </DocumentEditorContainerComponent>
        </div>
      </div>
    </div>
  )
}

export default CommentsComponent
