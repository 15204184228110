import {initializeApp} from 'firebase/app'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'

const config = {
  apiKey: 'AIzaSyDU2AzQSzciR85gy4nZEoQm0g_apBiaQDU',
  authDomain: 'supervised-c4183.firebaseapp.com',
  projectId: 'supervised-c4183',
  storageBucket: 'supervised-c4183.appspot.com',
  messagingSenderId: '48985799089',
  appId: '1:48985799089:web:5bddf764cad9bdf8b92590',

  // apiKey: 'AIzaSyBXpOb_gl7JnIbUsA-jSQLYnQe6Goa4sb4',
  // authDomain: 'skorler-dev-env.firebaseapp.com',
  // projectId: 'skorler-dev-env',
  // storageBucket: 'skorler-dev-env.appspot.com',
  // messagingSenderId: '1048810466695',
  // appId: '1:1048810466695:web:c642bd32917798d32fb722',

  // apiKey: 'AIzaSyAXEtuwwHHIBxZ7uj0GOMu-bWfqaSbjOBE',
  // authDomain: 'cizoti-dsp-skorler.firebaseapp.com',
  // projectId: 'cizoti-dsp-skorler',
  // storageBucket: 'cizoti-dsp-skorler.appspot.com',
  // messagingSenderId: '238810165656',
  // appId: '1:238810165656:web:faad8262a3cb1fbd21fbed',
}

const app = initializeApp(config)

const db = getFirestore(app)
const storage = getStorage(app)

export {db, storage}
