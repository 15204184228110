import React, {useCallback, useState} from 'react'
import {useSelector} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {selectAuth} from '../../../redux/selectors/auth'
import {KTIcon} from '../../../_metronic/helpers'
import {useDropzone} from 'react-dropzone'
import {useParams} from 'react-router-dom'
import {getDownloadURL, ref, uploadBytes} from 'firebase/storage'
import {storage} from '../../../utils/firebase'
import jsPDF from 'jspdf'
import put from '../../../lib/put'

type Props = {
  handleClose: Function
  refreshProject: Function
}

const documentSchema = Yup.object().shape({
  title: Yup.string().required('Document Title is required'),
  description: Yup.string(),
})

const initialValues = {title: '', description: '', defualtTemplate: '', content: ''}

const CreateDocument = ({handleClose, refreshProject}: Props) => {
  const {projectId} = useParams()

  const [fileContent, setFileContent] = useState<string | null>(null)
  const [fileUploadState, setFileUploadState] = useState<boolean | null | 'complete'>(null)

  const {token} = useSelector(selectAuth)

  const [loading, setLoading] = useState(false)

  const handleFileUpload = async (projectId: string, file: any) => {
    const fileType = file.name.split('.')[file.name.split('.').length - 1]

    const fileRefPathRef = ref(storage, `documents/projects/${projectId}/${file.name}`)
    await uploadBytes(fileRefPathRef, file)

    return {ref: fileRefPathRef, name: file.name, type: fileType}
  }

  const {getRootProps, getInputProps, acceptedFiles} = useDropzone({
    multiple: false,
    accept: {
      // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/pdf': ['.pdf'],
      // 'application/msword': ['.doc'],
    },
  })

  let files = acceptedFiles.map((file: any) => (
    <li key={file.path || file.name}>{file.path || file.name}</li>
  ))

  const formik = useFormik({
    initialValues,
    validationSchema: documentSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const fileUploadPromises = acceptedFiles.map((file) => handleFileUpload(projectId, file))
        const fileUploadData = await Promise.all(fileUploadPromises)

        const fileUrls = await Promise.all(
          fileUploadData.map(async (fileData) => {
            const url = await getDownloadURL(fileData.ref)
            return {
              path: url,
              title: values.title,
              description: values.description,
              type: fileData.type,
            }
          })
        )

        await put(
          `projects/uploadDocument/${projectId}`,
          {files: fileUrls},
          token,
          true,
          'Documents Submitted'
        ).then(() => {
          formik.resetForm()
          refreshProject()
        })

        handleClose()

        setSubmitting(false)
        setLoading(false)
      } catch (error: any) {
        console.log(error)
        setSubmitting(false)
        setLoading(false)

        if (error.response?.data.message) {
          return setStatus(error.response.data.message)
        }
        if (error.response?.data.error) {
          return setStatus(error.response.data.error)
        } else {
          return setStatus(error.error)
        }
      }
    },
  })

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'> Create document</h2>

              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => handleClose()}
                style={{cursor: 'pointer'}}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>{' '}
            <form
              className='form w-100 h-100'
              onSubmit={formik.handleSubmit}
              noValidate
              id='kt_login_signin_form'
            >
              {formik.status ? (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              ) : (
                ''
              )}

              <div className='modal-body'>
                <div className='row-fw mb-5'>
                  <label className='form-label fs-6 fw-bold required '>Title</label>
                  <input
                    placeholder='Enter Document Title'
                    {...formik.getFieldProps('title')}
                    className={clsx('form-control ')}
                    type='title'
                    name='title'
                    autoComplete='off'
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.title}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='row-fw mb-5'>
                  <label className='form-label fs-6 fw-bold '>Description</label>
                  <input
                    placeholder='Enter Document Description'
                    {...formik.getFieldProps('description')}
                    className={clsx('form-control ')}
                    type='description'
                    name='description'
                    autoComplete='off'
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.description}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='row-fw mb-5'>
                  <label className='form-label fs-6 fw-bold'>
                    <h4>Files : {files}</h4>
                  </label>{' '}
                  <div
                    {...getRootProps()}
                    className={`dropzone ${fileUploadState === 'complete' ? 'bg-success' : ''}`}
                    style={{
                      border: ` ${fileUploadState === 'complete' ? '1px solid green' : ''}`,
                    }}
                  >
                    <input {...getInputProps()} />
                    <p>
                      {fileContent && 'File Added'}
                      {!fileContent && 'Drag & drop a file here, or click to select one'}
                    </p>
                  </div>
                </div>
                {/* ) : (
                  ''
                )} */}

                <div className='row-fw mb-5'>
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-primary'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && <span className='indicator-label'>Submit</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default CreateDocument
