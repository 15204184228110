import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import post from '../../lib/post'
import {useSelector} from 'react-redux'
import {selectToken} from '../../redux/selectors/auth'
import {KTIcon} from '../../_metronic/helpers'
import Department from '../../types/Department'
import get from '../../lib/get'
import clsx from 'clsx'

const initialValues = {
  name: '',
  department: '',
}
const CreateProgrammeSchema = Yup.object().shape({
  name: Yup.string().required('Programme name is required'),
  department: Yup.string().required('Programme department is required'),
})

const CreateProgramme = ({setCreateNew, refreshProgramme}: any) => {
  const token = useSelector(selectToken)
  const [IsLoading, setIsLoading] = useState<boolean>(false)
  const [departments, setDepartments] = useState<Array<Department>>([])

  const formik = useFormik({
    initialValues,
    validationSchema: CreateProgrammeSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoading(true)
      try {
        await post('programmes', {...values}, token, true, 'Programme Created')
        if (1) {
          formik.values = initialValues
          refreshProgramme()
        }
        setCreateNew(false)

        setSubmitting(false)
        setIsLoading(false)
      } catch (error: any) {
        console.log(error)
        setSubmitting(false)
        setIsLoading(false)

        if (error.response?.data.message) {
          return setStatus(error.response.data.message)
        }
        if (error.response?.data.error) {
          return setStatus(error.response.data.error)
        } else {
          return setStatus(error.error)
        }
      }
    },
  })

  const getDepartments = async () => {
    if (token) {
      const RESPONSE = await get('departments', token)
      setDepartments(RESPONSE.data)
    }
  }
  useEffect(() => {
    getDepartments()
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            {/* end::Modal Backdrop */}
            <div className='modal-header pt-7' id='kt_chat_contacts_header'>
              <div className='modal-title'>
                <h2> Create Programme</h2>
              </div>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                style={{cursor: 'pointer'}}
                onClick={() => setCreateNew(false)}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>
            {/* begin::Modal body */}
            {/* Programmes, documents and files which haven't been edited or reviewed */}
            <div className='modal-body scroll-y mx-5 mx-xl-10 '>
              <div className='card-body'></div>
              <form
                className='form w-100 h-100'
                onSubmit={formik.handleSubmit}
                noValidate
                id='Programme_creation_form'
              >
                <div className='row mb-2 fv-plugins-icon-container'>
                  <label className='fs-6 fw-semibold form-label mt-3'>
                    <span className='required'>Name</span>
                  </label>
                  <input
                    type='text'
                    {...formik.getFieldProps('name')}
                    className='form-control form-control-solid'
                    placeholder='Programme name'
                  />
                  <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
                </div>
                <div className='row mb-7 fv-plugins-icon-container'>
                  <label className='fs-6 fw-semibold form-label mt-3'>
                    <span className='required'>Department</span>
                  </label>

                  <select
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.department && formik.errors.department},
                      {
                        'is-valid': formik.touched.department && !formik.errors.department,
                      }
                    )}
                    {...formik.getFieldProps('department')}
                    placeholder='Department'
                  >
                    <option value=''>Select Department</option>

                    {departments.map((e) => {
                      return (
                        <option key={e._id} value={e._id}>
                          {e.name}
                        </option>
                      )
                    })}
                  </select>

                  {/* end::Input */}
                  {formik.touched.department && formik.errors.department && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        {/* <span role='alert'>{formik.errors.department}</span> */}
                      </div>
                    </div>
                  )}
                  <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
                </div>

                <div className='d-flex justify-content-end'>
                  <button
                    type='reset'
                    data-kt-contacts-type='cancel'
                    className='btn btn-light me-3'
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-primary'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    <i className='ki-duotone ki-badge fs-2  mx-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                      <span className='path4'></span>
                      <span className='path5'></span>
                    </i>
                    {!IsLoading && <span className='indicator-label'>Create</span>}
                    {IsLoading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/* end::Modal body */}
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}

      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default CreateProgramme
