import User from '../../../../types/User'

const ProjectSupervisors = ({
  projectsSupervisors,
}: {
  projectsSupervisors: Array<{title: string; supervisors: Array<User>}>
}) => {
  return (
    <div className='h-50'>
      <div className='card card-stretch '>
        <div className='card-header border-0 pt-5 '>
          <h3 className='card-title align-items-start flex-column pb-0 mb-0'>
            <span className='card-label fw-bold fs-3 '>Supervisors</span>
            {/* <span className='text-muted mt-1 fw-semibold fs-7'>
            Supervisors {projectsSupervisors?.length || 0}
          </span> */}
          </h3>
        </div>
        <div className='card-body py-1 scroll'>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead className='border-top-1 border-bottom-1  border-gray-400'>
              <tr className='fw-bold text-muted border-0'>
                <th className=''>Supervisors</th>

                <th className=''>Project title</th>
              </tr>
            </thead>
            <tbody>
              {projectsSupervisors?.length > 0 &&
                projectsSupervisors.map((projectsSupervisor, index) => (
                  <tr key={index}>
                    <td>
                      {projectsSupervisor.supervisors.map((sup, i) => (
                        <div key={i} className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {sup.lastName +
                            '  ' +
                            sup.firstName +
                            `${i % 2 === 0 && i !== 0 ? ',' : ''}`}
                          {}
                        </div>
                      ))}
                      <span className='text-muted fw-semibold d-block fs-7'></span>
                    </td>
                    <td>
                      <span className='text-muted fw-semibold d-block fs-7'>
                        {projectsSupervisor?.title}
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ProjectSupervisors
