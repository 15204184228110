import {useEffect, useState} from 'react'
import get from '../../lib/get'
import {useSelector} from 'react-redux'
import {RootState} from '../../redux/store'

import ProposalsTable from './components/proposalsTable'

const MyProposals = ({role = 'Proposals'}) => {
  const token = useSelector((state: RootState) => state.auth.token)
  const [proposals, setProposals] = useState([])
  const [isLoading, setIsLoading] = useState<boolean>()

  useEffect(() => {
    const handleSetProposals = async () => {
      setProposals(proposals)
      try {
        const RESPONSE = await get('proposals', token)
        setProposals(RESPONSE.data)
        setIsLoading(false)
      } catch (error) {
        setProposals([])
        setIsLoading(false)
      }
    }

    handleSetProposals()
  }, [])

  //   const handleModalUpdate = (newuser: User | null) => {
  //     newuser ? setItemIdForUpdate(newuser._id) : setItemIdForUpdate(null)
  //     getProposals(newuser)
  //   }

  return <ProposalsTable role={'Proposals'} proposals={proposals} isLoading={isLoading} />
}

export default MyProposals
