import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {selectAuth} from '../redux/selectors/auth'
import post from '../lib/post'
import {KTIcon, toAbsoluteUrl} from '../_metronic/helpers'
import {useDropzone} from 'react-dropzone'
import {useParams} from 'react-router-dom'
import {getDownloadURL, ref, uploadBytes} from 'firebase/storage'
import {storage} from '../utils/firebase'
import put from '../lib/put'
import {TProject} from '../types/Project'
import FormatDate from '../utils/FormatDate'
import {PDFDownloadLink} from '@react-pdf/renderer'
import ProjectPDF from '../pdf-export/project-details'

type Props = {
  handleClose: Function
  projectViewOpen: TProject
}

const documentSchema = Yup.object().shape({
  title: Yup.string().required('Document Title is required'),
  description: Yup.string().required('Document Description is required'),
})

const initialValues = {title: '', description: '', defualtTemplate: '', content: ''}

const ViewProject = ({handleClose, projectViewOpen}: Props) => {
  const {projectId} = useParams()

  const [fileContent, setFileContent] = useState<string | null>(null)
  const [fileUploadState, setFileUploadState] = useState<boolean | null | 'complete'>(null)

  const {token} = useSelector(selectAuth)

  const [loading, setLoading] = useState(false)

  const hadlefileFileUpload = async (projectId: string, file: any) => {
    const fileRefPathRef = ref(storage, `documents/projects/${projectId}/${file.name}`)
    await uploadBytes(fileRefPathRef, file).then((snapshot) => {})

    return {ref: fileRefPathRef, name: file.name}
  }

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>View Project</h2>

              <div>
                {/* {projectViewOpen.title && projectViewOpen && (
                  <PDFDownloadLink
                    document={<ProjectPDF project={projectViewOpen} />}
                    fileName={`${projectViewOpen.title}_details.pdf`}
                  >
                    {({blob, url, loading, error}) => {
                      return loading ? (
                        'Loading document...'
                      ) : (
                        <button className='btn btn-sm btn-primary mx-1'>
                          <KTIcon iconName='file-down' className='fs-2' />
                          Export
                        </button>
                      )
                    }}
                  </PDFDownloadLink>
                )} */}

                <div
                  className='btn btn-icon btn-sm btn-active-icon-primary'
                  data-kt-users-modal-action='close'
                  onClick={() => handleClose()}
                  style={{cursor: 'pointer'}}
                >
                  <KTIcon iconName='cross' className='fs-1' />
                </div>
              </div>
            </div>{' '}
            <div className='modal-body'>
              <div className='d-flex gap-7 align-items-center mb-8'>
                <div className='symbol symbol-circle symbol-100px'>
                  <img alt='Pic' src={toAbsoluteUrl(`/media/avatars/blank.png`)} />
                </div>
                <div className='d-flex flex-column gap-2'>
                  <h3 className='mb-0'>
                    {projectViewOpen?.student.firstName + ' ' + projectViewOpen?.student.lastName}
                  </h3>
                  <div className='d-flex align-items-center gap-2'>
                    <i className='ki-duotone ki-sms fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                    <a href='#' className='text-muted text-hover-primary'>
                      {projectViewOpen?.student.email}
                    </a>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-column gap-5 '>
                <div className='d-flex flex-column gap-1'>
                  <div className='fw-bold text-muted'> Title </div>
                  <div className='fw-bold fs-5'>{projectViewOpen?.title}</div>
                </div>
                <div className='d-flex flex-column gap-1'>
                  <div className='fw-bold text-muted'>Description</div>
                  <div className='fw-bold fs-5'>{projectViewOpen?.description}</div>
                </div>
                <div className='d-flex flex-column gap-1'>
                  <div className='fw-bold text-muted'>DueDate</div>
                  <div className='fw-bold fs-5'>
                    {projectViewOpen?.dueDate ? FormatDate(projectViewOpen?.dueDate) : 'Not Set'}
                  </div>
                </div>
                <div className='d-flex flex-column gap-1'>
                  <div className='fw-bold text-muted'>Programme</div>
                  <div className='fw-bold fs-5'>{projectViewOpen?.programme?.name}</div>
                </div>
                <div className='d-flex flex-column gap-1'>
                  <div className='fw-bold text-muted'>Date Submitted</div>
                  <div className='fw-bold fs-5'>{FormatDate(projectViewOpen?.createdAt)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default ViewProject
