import {useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import StudentOverviewHeader from './view-project-header'
import FileViewer from '../../../../components/FileViewer'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import get from '../../../../lib/get'
import {useSelector} from 'react-redux'
import {selectToken} from '../../../../redux/selectors/auth'
import {TProject} from '../../../../types/Project'

const ViewProject = () => {
  const token = useSelector(selectToken)

  const {projectId} = useParams()
  const [project, setProject] = useState<TProject | null>(null)

  useEffect(() => {
    const getProject = async () => {
      const RESPONSE = await get(`projects/${projectId}`, token)
      if (RESPONSE?.data) setProject(RESPONSE.data)
    }

    getProject()
  }, [projectId])

  const viewProjectBreadcrumbs: Array<PageLink> = [
    {
      title: 'View Project',
      path: '/students/viewProject',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: project?.student?.lastName + ' ' + project?.student?.firstName,
      path: '/students/viewProject',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const [viewerComponent, setViewerComponent] = useState()
  const headerTop = useRef(null)
  const headerBottom = useRef(null)

  const headerRef = {
    headerTop,
    headerBottom,
  }

  const [viewDoc, setViewDoc] = useState(null)
  const navigate = useNavigate()

  return (
    <>
      <PageTitle breadcrumbs={viewProjectBreadcrumbs}>{project?.title}</PageTitle>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-12  '>
          <StudentOverviewHeader headerRef={headerRef} setViewDoc={setViewDoc} viewDoc={viewDoc} />
        </div>
      </div>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-12'>
          {viewDoc && <FileViewer headerRef={headerRef} file={viewDoc} />}
        </div>
      </div>
    </>
  )
}

export default ViewProject
