import React, {useEffect, useMemo, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link, useParams} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {TProject} from '../../../types/Project'
import {selectAuth, selectUser} from '../../../redux/selectors/auth'
import ViewProject from '../../../components/viewProject'
import {FaArrowDown, FaArrowUp} from 'react-icons/fa'
type Props = {
  projects: Array<TProject>
  setProjects: Function
  isLoading: boolean
  setIsLoading: Function
}

const Projects = ({projects, setProjects, isLoading, setIsLoading}: Props) => {
  const currentUser = useSelector(selectUser)
  const [isProjectViewOpen, setIsProjectViewOpen] = useState<boolean>(false)
  const [ProjectViewOpen, setProjectViewOpen] = useState<TProject>()
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [filter, setFilter] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [sortColumn, setSortColumn] = useState<string>(null)
  const [sortDirection, setSortDirection] = useState(null)

  const handlePageClick = (page: any) => {
    setCurrentPage(page)
  }
  const handleProjectViewClose = (page: any) => {
    setIsProjectViewOpen(page)
  }
  const handleProjectViewOpen = (project: TProject) => {
    setIsProjectViewOpen(true)
    setProjectViewOpen(project)
  }
  const currentDate: any = new Date()

  const filteredData = useMemo(() => {
    setCurrentPage(0)
    return projects?.filter((project) =>
      Object.values(project).some(
        (value) =>
          (typeof value === 'string' &&
            value.toLowerCase().includes(searchTerm.trim().toLowerCase())) ||
          project.student.firstName.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
          project.student.lastName.toLowerCase().includes(searchTerm.trim().toLowerCase())
      )
    )
  }, [projects, searchTerm])

  const sortedData = sortColumn
    ? [...filteredData].sort((a, b) => {
        let aValue
        let bValue
        if (sortColumn === 'title') {
          aValue = String(a[sortColumn]).toLowerCase()
          bValue = String(b[sortColumn]).toLowerCase()
        }
        if (sortColumn === 'student') {
          aValue = String(a[sortColumn]?.firstName + a[sortColumn]?.lastName).toLowerCase()
          bValue = String(b[sortColumn]?.firstName + b[sortColumn]?.lastName).toLowerCase()
        }

        if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1
        if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1
        return 0
      })
    : filteredData

  const paginatedData = useMemo(() => {
    const startIndex = currentPage * pageSize
    return sortedData.slice(startIndex, startIndex + pageSize)
  }, [sortedData, currentPage, pageSize])

  const totalPages = Math.ceil(sortedData?.length / pageSize)

  const paginationItems = []

  const handleSort = (column: any) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortColumn(column)
      setSortDirection('asc')
    }
  }

  for (let i = 0; i < totalPages; i++) {
    paginationItems.push(
      <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
        <button className='page-link' onClick={() => handlePageClick(i)}>
          {i + 1}
        </button>
      </li>
    )
  }
  return (
    <>
      <div style={{minHeight: '100%', position: 'relative'}}>
        <div className={`card mb-5 mb-xl-8`} style={{minHeight: '100%', position: 'relative'}}>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Projects</span>
              <span className='text-muted mt-1 fw-semibold fs-7'>
                Total projects {projects ? projects.length : ''}
              </span>
            </h3>

            <div className='card-toolbar'>
              <div className='d-flex align-items-center position-relative me-4'>
                <i className='ki-duotone ki-magnifier fs-3 position-absolute ms-3'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
                <input
                  onChange={(e) => setSearchTerm(e.target.value)}
                  type='text'
                  id='kt_filter_search'
                  className='form-control form-control-sm form-control-solid w-150px ps-10'
                  placeholder='Search'
                />
              </div>
              {currentUser?.roles.some((role) => role._id === '6520a3d7f01dc89c37c76329') && (
                <>
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <KTIcon iconName='category' className='fs-2' />
                  </button>
                  <div
                    className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-200px'
                    data-kt-menu='true'
                  >
                    <div className='menu-item px-3'>
                      <div className='menu-content fs-6 text-dark fw-bold px-3 py-4'>
                        Quick Actions
                      </div>
                    </div>
                    <div className='separator mb-3 opacity-75'></div>

                    <div className='menu-item px-3'>
                      <a onClick={() => null} className='menu-link px-3'>
                        New Proposal
                      </a>
                    </div>

                    <div className='separator mt-3 opacity-75'></div>

                    {/* <div className='menu-item px-3'>
                <div className='menu-content px-3 py-3'>
                  <a className='btn btn-primary btn-sm px-4' href='#'>
                    Generate Reports
                  </a>
                </div>
              </div> */}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead className='border-top-1 border-bottom-1  border-gray-400'>
                  <tr className='fw-bold text-muted border-0'>
                    <th
                      style={{cursor: 'pointer'}}
                      onClick={() => handleSort('title')}
                      className='min-w-240px max-w-150px position-relative'
                    >
                      Title
                      {sortColumn === 'title' && (
                        <span className='position-absolute top-50 start-50 translate-middle'>
                          {sortDirection === 'asc' ? (
                            <FaArrowUp size={10} />
                          ) : (
                            <FaArrowDown size={10} />
                          )}
                        </span>
                      )}
                    </th>
                    {currentUser?.roles.some((role: any) => role.name !== 'Student') && (
                      <th
                        onClick={() => handleSort('student')}
                        style={{cursor: 'pointer'}}
                        className='min-w-120px  position-relative'
                      >
                        Student
                        {sortColumn === 'student' && (
                          <span className='position-absolute top-50 start-50 translate-middle'>
                            {sortDirection === 'asc' ? (
                              <FaArrowUp size={10} />
                            ) : (
                              <FaArrowDown size={10} />
                            )}
                          </span>
                        )}
                      </th>
                    )}
                    <th
                      style={{cursor: 'pointer'}}
                      className='min-w-200px'
                      onClick={() => handleSort('status')}
                    >
                      Status
                      {sortColumn === '_id' && (
                        <span className='position-absolute top-50 start-50 translate-middle'>
                          {sortDirection === 'asc' ? (
                            <FaArrowUp size={10} />
                          ) : (
                            <FaArrowDown size={10} />
                          )}
                        </span>
                      )}
                    </th>
                    {currentUser?.roles.some(
                      (role) =>
                        role._id === '65211371fab16366b9406330' ||
                        role._id === '6520a3d7f01dc89c37c76329' ||
                        role._id === '652deb5373b4597d16737bd6'
                    ) && <th className='min-w-250px text-center'>Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {isLoading && (
                    <tr className='fw-bold text-muted'>
                      <td colSpan={5}>
                        <div className='fv-row d-flex justify-content-center mh-300px py-10'>
                          <div className='fv-row d-flex justify-content-center mh-300px'>
                            <div className='h-40px w-40px spinner-border spinner-border-sm align-middle ms-2'></div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                  {projects?.length > 0 &&
                    paginatedData.map((project: TProject) => {
                      const student =
                        typeof project.student === 'object' ? project.student._id : project.student

                      const deadlineDate: any = new Date(project.dueDate)
                      const startDate: any = new Date(project.createdAt)
                      const StartToEndInMilliseconds = deadlineDate - startDate
                      const StartToCurrentInMilliseconds = currentDate - startDate
                      let StartToDeadlineDays = Math.round(
                        StartToEndInMilliseconds / (1000 * 60 * 60 * 24)
                      )
                      let StartToCurrentDays = Math.round(
                        StartToCurrentInMilliseconds / (1000 * 60 * 60 * 24)
                      )
                      const progressPercentage = (StartToCurrentDays / StartToDeadlineDays) * 100
                      const isOverdue = StartToCurrentDays > StartToDeadlineDays

                      let progressBarColor = ''
                      if (isOverdue) {
                        progressBarColor = 'red'
                      } else if (progressPercentage > 90 && progressPercentage <= 100) {
                        progressBarColor = 'orange'
                      } else {
                        progressBarColor = '' // Default color
                      }

                      return (
                        <tr key={project._id}>
                          <td style={{maxWidth: 280, cursor: 'pointer'}}>
                            <span
                              onClick={() => handleProjectViewOpen(project)}
                              className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 pe-auto'
                            >
                              {project.title}
                            </span>
                          </td>
                          {currentUser?.roles.some((role: any) => role.name !== 'Student') && (
                            <td>
                              <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                {project?.student?.lastName + ' ' + project?.student?.firstName}
                              </span>
                              <span className='fw-semibold text-muted d-block fs-7'>
                                {project?.student?.department?.name}
                              </span>
                            </td>
                          )}
                          <td>
                            <div className='progress position-relative' style={{height: '35px'}}>
                              <div
                                className='progress-bar'
                                role='progressbar'
                                style={{
                                  width: `${isOverdue ? 100 : progressPercentage}%`,
                                  backgroundColor: progressBarColor,
                                }}
                                aria-valuenow={25}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              >
                                {(isOverdue || progressPercentage > 12) && (
                                  <span className='fw-bold'>
                                    {isOverdue
                                      ? `${Math.abs(Math.ceil(progressPercentage - 100))}% overdue`
                                      : `${Math.floor(progressPercentage)}%`}
                                  </span>
                                )}
                              </div>
                              {!isOverdue && progressPercentage < 12 && (
                                <span
                                  style={{top: 10, left: '50%', fontWeight: 500, fontSize: 12}}
                                  className='position-absolute'
                                >
                                  {`${Math.ceil(progressPercentage)}%`}
                                </span>
                              )}
                            </div>
                          </td>
                          <td className='text-center'>
                            {currentUser?.roles.some(
                              (role) => role._id === '65211371fab16366b9406330'
                            ) && (
                              <Link
                                to={`/students/assignedStudents/${student}/project/${project._id}`}
                                title='View'
                                className='btn btn-primary btn-sm me-1 mr-1'
                              >
                                <KTIcon iconName='eye' className='fs-3' />
                                View Project
                              </Link>
                            )}
                            {currentUser?.roles.some(
                              (role) =>
                                role._id === '6520a3d7f01dc89c37c76329' ||
                                role._id === '652deb5373b4597d16737bd6'
                            ) && (
                              <Link
                                to={`/project/${project._id}`}
                                title='View'
                                className='btn btn-primary btn-sm me-1 mr-1'
                              >
                                <KTIcon iconName='eye' className='fs-3' />
                                View Project
                              </Link>
                            )}
                          </td>
                        </tr>
                      )
                    })}

                  {projects.length === 0 && !isLoading && (
                    <tr>
                      <td colSpan={7}>
                        <div className='fv-row d-flex justify-content-center mh-300px'>
                          <div className='fv-row d-flex justify-content-center mh-300px fs-5 py-20'>
                            <span className='text-muted'> No projects</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {!projects && isLoading && (
          <div className='fv-row d-flex justify-content-center mh-300px'>
            <div className='h-40px w-40px spinner-border spinner-border-sm align-middle ms-2'></div>
          </div>
        )}
        {projects.length === 0 && !isLoading && (
          <div className='fv-row d-flex justify-content-center mh-300px fs-5 py-20'>
            <span className='text-muted'> No Projects</span>
          </div>
        )}
        <div className='d-flex' style={{position: 'absolute', bottom: '20px', right: '20px'}}>
          <select
            name='status'
            data-control='select2'
            data-hide-search='true'
            className='form-select form-select-sm form-select-solid w-80px select2-hidden-accessible'
            tabIndex={-1}
            aria-hidden='true'
            data-kt-initialized='1'
            onChange={(e) => {
              setCurrentPage(0)
              setPageSize(Number(e.target.value))
            }}
            defaultValue={pageSize}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
          <ul className='pagination'>
            <li className={`page-item previous ${currentPage === 0 ? 'disabled' : ''}`}>
              <a href='#' className='page-link' onClick={() => handlePageClick(currentPage - 1)}>
                <i className='previous'></i>
              </a>
            </li>
            {paginationItems}
            <li className={`page-item next ${currentPage === totalPages - 1 ? 'disabled' : ''}`}>
              <a href='#' className='page-link' onClick={() => handlePageClick(currentPage + 1)}>
                <i className='next'></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      {isProjectViewOpen && (
        <ViewProject handleClose={handleProjectViewClose} projectViewOpen={ProjectViewOpen} />
      )}
    </>
  )
}

export default Projects
