/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import Notifications from '../components/notifications'
import get from '../../../lib/get'
import {selectToken} from '../../../redux/selectors/auth'
import {useSelector} from 'react-redux'
import {ChartWidget} from '../components/supervisor/Chart'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Dashboard: FC = () => {
  const token = useSelector(selectToken)
  const [workflowData, setWorkflowData] = useState<any>()
  const [workflowKeys, setWorkflowKeys] = useState<any[]>()
  const [overdueProjects, setOverdueProjects] = useState<any[]>()

  useEffect(() => {
    const getDashboardData = async () => {
      try {
        const RESPONSE = await get('projects/supervisor/dashboardData', token)
        if (RESPONSE) {
          setWorkflowData(RESPONSE.countsMap)
          setWorkflowKeys(Object.keys(RESPONSE.countsMap))
          setOverdueProjects(RESPONSE.overdueProjects)
        }
      } catch (error) {
        console.log(error)
      }
    }
    getDashboardData()
  }, [token])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  }
  return (
    <>
      <div className='row g-5' style={{height: '525px'}}>
        <div className='col-lg-6 d-flex flex-column h-100'>
          {workflowKeys?.length !== 0 && workflowData && (
            <div className='h-50 ' style={{overflow: 'hidden'}}>
              <Slider {...settings}>
                {workflowKeys?.map((key: string) => (
                  <div key={key}>
                    <ChartWidget workflowData={workflowData[key]} />
                  </div>
                ))}
              </Slider>
            </div>
          )}

          {!workflowKeys && !workflowData && (
            <div className='h-50'>
              <div className='card card-stretch'>
                <span className='text-muted'> Loading</span>
              </div>
            </div>
          )}

          {workflowKeys?.length === 0 && (
            <div className='h-50'>
              <div className='card card-stretch d-flex align-items-center justify-content-center'>
                <span className='text-muted'> No Workflow Files</span>
              </div>
            </div>
          )}
          <div className='h-50'>
            <div className='card card-stretch'>
              <div className='card-header border-0'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 '>Overdue Projects</span>
                </h3>
              </div>
              <div className='card-body py-1 scroll'>
                <table className='table align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='fw-bold fs-6 text-muted'>
                      <th className='min-w-120px'>Project Title</th>
                      <th className='min-w-100px'>Status</th>
                      <th className='min-w-100px'>Progress</th>
                    </tr>
                  </thead>
                  <tbody>
                    {overdueProjects?.length > 0 ? (
                      overdueProjects.map((project, index) => {
                        const deadlineDate: any = new Date(project.dueDate)
                        const currentDate: any = new Date()
                        const startDate: any = new Date(project.createdAt)
                        const totalDuration = deadlineDate - startDate
                        const elapsedDuration = currentDate - startDate
                        const progress = (elapsedDuration / totalDuration) * 100
                        return (
                          <tr
                            key={index}
                            style={{display: 'table', tableLayout: 'fixed', width: '100%'}}
                          >
                            <td>
                              <span className='text-dark fw-bold d-block fs-6'>
                                {project.title}
                              </span>
                            </td>
                            <td>
                              <span className='text-muted fw-semibold d-block fs-7'>
                                {project.status.name}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`badge ${
                                  progress >= 100 ? 'badge-danger' : 'badge-warning'
                                }`}
                              >
                                {progress.toFixed(2)}%
                              </span>
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={3} className='mh-100px'>
                          <div className='fv-row d-flex justify-content-center mh-100px'>
                            <div className='fv-row d-flex justify-content-center mh-100px fs-5 py-10'>
                              <span className='text-muted'>No Overdue Projects</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-6 d-flex flex-column h-100'>
          <Notifications />
        </div>
      </div>
    </>
  )
}

const SupervisorDashboard: FC = () => {
  const SupervisorDashboardBreadcrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'Dashboard',
      path: '/dashboard',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={SupervisorDashboardBreadcrumbs}>Dashboard</PageTitle>
      <Dashboard />
    </>
  )
}

export {SupervisorDashboard}
