import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {selectAuth} from '../../../redux/selectors/auth'
import post from '../../../lib/post'
import {KTIcon} from '../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import TWorkflow from '../../../types/Workflow'
import get from '../../../lib/get'
import User from '../../../types/User'
import {TProposal} from '../../../types/Proposal'

type Props = {
  handleClose: Function
  proposal: TProposal
}

const documentSchema = Yup.object().shape({
  workflow: Yup.string().required('Document Workflow is required'),
})

const initialValues = {workflow: ''}

const ProposalApproval = ({handleClose, proposal}: Props) => {
  const {projectId} = useParams()

  const [supervisorDrpdw, setSupervisorDrpdw] = useState<boolean>(false)
  const {token} = useSelector(selectAuth)

  const [loading, setLoading] = useState(false)
  const [workflows, setWorkflows] = useState<Array<TWorkflow>>([])
  const [IsLoading, setIsLoading] = useState<boolean>(false)
  const [selectedSupervisors, selectSupervisor] = useState<Array<User>>([])
  const [supervisors, setSupervisors] = useState<Array<User>>()

  const getWorkflows = async () => {
    const RESPONSE = await get(`workflows`, token)
    if (RESPONSE?.data) {
      setWorkflows(RESPONSE.data)
    }
  }

  const removeSupervisor = (supervisor: User) => {
    selectSupervisor(selectedSupervisors.filter((sup) => sup._id !== supervisor._id))
  }

  const getSupervisors = async () => {
    const RESPONSE = await get(`users/supervisors`, token)
    if (RESPONSE.data) {
      setSupervisors(RESPONSE.data)
    }
  }

  const handleSetSupervisor = (supervisor: User) => {
    if (!selectedSupervisors.find((sup) => sup._id === supervisor._id)) {
      selectSupervisor([supervisor, ...selectedSupervisors])
    }

    setSupervisorDrpdw(false)
  }

  const toggleSupDrdw = () => {
    setSupervisorDrpdw(!supervisorDrpdw)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: documentSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      try {
        console.log(values)
        console.log(selectedSupervisors)
        await post(
          'proposals/approve',
          {...proposal, ...values, supervisors: selectedSupervisors.map((sup) => sup._id)},
          token,
          true,
          'Project Approved'
        )

        handleClose()

        setSubmitting(false)
        setLoading(false)
      } catch (error: any) {
        console.log(error)
        setSubmitting(false)
        setLoading(false)

        if (error.response?.data.message) {
          return setStatus(error.response.data.message)
        }
        if (error.response?.data.error) {
          return setStatus(error.response.data.error)
        } else {
          return setStatus(error.error)
        }
      }
    },
  })

  useEffect(() => {
    getWorkflows()
    getSupervisors()
  }, [])
  // const handleFormatChange = (e: any) => {
  //   console.log(e.target.value)
  //   if (e.target.value !== 'upload_word_file') {
  //     setFileContent(null)
  //   }
  // }
  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'> Approve project</h2>

              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => handleClose()}
                style={{cursor: 'pointer'}}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>{' '}
            <form
              className='form w-100 h-100'
              onSubmit={formik.handleSubmit}
              noValidate
              id='kt_login_signin_form'
            >
              {formik.status ? (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              ) : (
                ''
              )}

              <div className='modal-body'>
                <div className='row-fw mb-5'>
                  <label className='form-label fs-6 fw-bold '>Workflow</label>
                  <select
                    placeholder='Select workflow'
                    {...formik.getFieldProps('workflow')}
                    className={clsx('form-control ')}
                    autoComplete='off'
                  >
                    <option value=''>Select Workflow</option>

                    {workflows.map((workflow) => (
                      <option value={workflow._id}>{workflow.title}</option>
                    ))}
                  </select>

                  {formik.touched.workflow && formik.errors.workflow && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.workflow}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='row mb-4'>
                  <div className='fs-6 fw-semibold mt-2 mb-3'> Supervisor</div>
                  <div className='col-xl-12 fv-row fv-plugins-icon-container'>
                    <div className='' style={{position: 'relative'}}>
                      <div
                        onClick={() => toggleSupDrdw()}
                        className='form-control form-control-solid p-5 d-flex gap-3'
                      >
                        {selectedSupervisors.length > 0
                          ? selectedSupervisors.map((sup: User) => (
                              <div
                                style={{position: 'relative'}}
                                className='w-150px rounded  d-flex flex-column align-items-center bg-light p-4'
                              >
                                <div
                                  className='btn btn-icon absolute btn-sm btn-active-icon-primary'
                                  data-kt-users-modal-action='close'
                                  onClick={() => removeSupervisor(sup)}
                                  style={{
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    top: 5,
                                    right: 2,
                                  }}
                                >
                                  <KTIcon iconName='cross' className='fs-1 text-primary' />
                                </div>
                                <div className='symbol symbol-40px symbol-circle'>
                                  <span className='symbol-label fs-5 bg-secondary text-inverse-secondary fw-bold'>
                                    {sup?.lastName[0]}
                                  </span>
                                </div>
                                <div className='ms-4'>
                                  <span className='fs-6 fw-bold text-gray-900 text-hover-primary mb-2'>
                                    {sup.lastName + ' ' + sup.firstName}
                                  </span>
                                  <div className='fw-semibold fs-7 text-muted'>{sup.email}</div>
                                </div>
                              </div>
                            ))
                          : 'Select a supervisor'}
                      </div>
                      {supervisorDrpdw && (
                        <div
                          style={{position: 'absolute', zIndex: 3}}
                          className='card card-flush'
                          id='kt_contacts_list'
                        >
                          <div className='card-header pt-7' id='kt_contacts_list_header'>
                            <div className='row'>
                              <div className='d-flex align-items-center position-relative w-100 m-0'>
                                <div>
                                  <i className='ki-duotone ki-magnifier fs-3 text-gray-500 position-absolute top-50 ms-5 translate-middle-y'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </i>
                                  <input
                                    type='text'
                                    className='form-control form-control-solid ps-13'
                                    name='search'
                                    defaultValue=''
                                    placeholder='Search supervisors'
                                  />
                                </div>
                                <div
                                  className='btn btn-icon btn-sm btn-active-icon-primary'
                                  data-kt-users-modal-action='close'
                                  onClick={() => toggleSupDrdw()}
                                  style={{cursor: 'pointer'}}
                                >
                                  <KTIcon iconName='cross' className='fs-1' />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='card-body pt-5' id='kt_contacts_list_body'>
                            <div
                              className='scroll-y me-n5 pe-5 h-300px h-xl-auto'
                              data-kt-scroll='true'
                              data-kt-scroll-activate='{default: false, lg: true}'
                              data-kt-scroll-max-height='auto'
                              data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_contacts_list_header'
                              data-kt-scroll-wrappers='#kt_content, #kt_contacts_list_body'
                              data-kt-scroll-stretch='#kt_contacts_list, #kt_contacts_main'
                              data-kt-scroll-offset='5px'
                              style={{maxHeight: '763px'}}
                            >
                              {supervisors.length > 0 &&
                                supervisors &&
                                !IsLoading &&
                                supervisors.map((supervisor) => (
                                  <div
                                    key={supervisor._id}
                                    className='d-flex  flex-stack py-4 btn btn-active-light-info'
                                    onClick={() => handleSetSupervisor(supervisor)}
                                  >
                                    <div className='d-flex align-items-center '>
                                      <div className='symbol symbol-40px symbol-circle'>
                                        <span className='symbol-label bg-secondary text-inverse-secondary fw-bold'>
                                          {supervisor?.lastName[0]}
                                        </span>
                                      </div>
                                      <div className='ms-4'>
                                        <span className='fs-6 fw-bold text-gray-900 text-hover-primary mb-2'>
                                          {supervisor.lastName + ' ' + supervisor.firstName}
                                        </span>
                                        <div className='fw-semibold fs-7 text-muted'>
                                          {supervisor.email}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              {IsLoading && (
                                <div className='fv-row d-flex justify-content-center mh-300px'>
                                  <div className='h-40px w-40px spinner-border spinner-border-sm align-middle ms-2'></div>
                                </div>
                              )}
                              <div className='separator separator-dashed d-none'></div>
                            </div>
                          </div>
                        </div>
                      )}
                      {!selectedSupervisors.length && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>Supervisor is required</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
                  </div>
                </div>

                <div className='row-fw mb-5'>
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-primary'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && <span className='indicator-label'>Submit</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default ProposalApproval
