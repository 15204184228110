/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../_metronic/layout/core'

import {useSelector} from 'react-redux'
import {selectAuth} from '../../redux/selectors/auth'
import {AdminDashboard} from './roles/AdminDashboard'
import {StudentDashboard} from './roles/StudentDashboard'
import {SupervisorDashboard} from './roles/InstructorDashboard'
import {FacultyAdminDashboard} from './roles/FacultyAdmin'

const DashboardPage: FC = () => {
  const auth = useSelector(selectAuth)
  return (
    <>
      {auth.user?.roles.some((role) => role._id === '651c8fab03a0295e6de56b99') ? (
        <AdminDashboard />
      ) : (
        ''
      )}
      {auth.user?.roles.some((role) => role._id === '6520a3d7f01dc89c37c76329') ? (
        <StudentDashboard />
      ) : (
        ''
      )}
      {auth.user?.roles.some((role) => role._id === '65211371fab16366b9406330') ? (
        <SupervisorDashboard />
      ) : (
        ''
      )}
      {auth.user?.roles.some((role) => role._id === '652deb5373b4597d16737bd6') ? (
        <FacultyAdminDashboard />
      ) : (
        ''
      )}
    </>
  )
}
const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
